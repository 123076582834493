const methods = {
    // # 메인 결과값
    result_main(list, sub_tab){
        // # 객체계산식을 문자열계산식으로 변환
        let formula = this.$convertFormulaListToString(list)
        
        // # 계산식에서 빠진 뒷괄호 추가
        formula = this.$addMissingParentheses(formula)

        // # 목록에 표시할 숫자
        if(list.length >= 1){
            this.$store.state.result = this.$math.evaluate(formula)
        }

        // # 배열요소가 1개 이하일떄는 결과값 표시하지 않음
        if(list.length <= 1){
            return ''
        }

        let value
        value = this.$math.evaluate(formula)
        value = this.$removeInfiniteDecimal(value)
        if(sub_tab != "000"){
            if(sub_tab == "만억조"){
                value = this.$caculateNumberToMOJ2(value)
            }
            else if(sub_tab == "KMB"){
                value = this.$caculateNumberToKMB2(value)
            }
        }
        value = this.$toComma(value)

        return value
    },


    // # 서브 결과값
    result_sub(list, sub_tab, input_tab){
        // # 객체계산식을 문자열계산식으로 변환
        let formula = this.$convertFormulaListToString(list)

        // # 계산식에서 빠진 뒷괄호 추가
        formula = this.$addMissingParentheses(formula)

        // # 배열요소가 1개 이하일떄는 결과값 표시하지 않음
        if(list.length <= 1){
            return ''
        }

        // # 단위 이하 표시하지 않기
        if(sub_tab == "만억조" && /^-?\d{5,}$/.test(this.$math.evaluate(formula).toString())){
            return ''
        }
        else if(sub_tab == "KMB" && /^-?\d{4,}$/.test(this.$math.evaluate(formula).toString())){
            return ''
        }

        let value
        value = this.$math.evaluate(formula)
        value = this.$removeInfiniteDecimal(value)
        if(sub_tab == "000"){
            if(input_tab == "만억조"){
                value = this.$caculateNumberToMOJ2(value)
            }
            else if(input_tab == "KMB"){
                value = this.$caculateNumberToKMB2(value)
            }
        }
        value = this.$toComma(value)

        return value
    },

    
    // # 환전 메인 결과값
    result_currency_second_main(list, sub_tab, currency_origin, currency_second){
        if(list.length == 0){
            if(currency_origin == currency_second){
                return ''
            }else{
                // return this.$math.divide(currency_origin, currency_second).toFixed(this.$store.state.sosujum)
                return this.$math.divide(currency_origin, currency_second).toFixed(2).replace(/\.00$/, '')
            }
        }

        // # 객체계산식을 문자열계산식으로 변환

        let formula = this.$convertFormulaListToString(list)
        // # 계산식에서 빠진 뒷괄호 추가
        formula = this.$addMissingParentheses(formula)

        // # 계산값 표시
        if(sub_tab == "000"){
            try {
                return this.$toComma(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, ''))
            } catch (error) {
                return ''
            }
        }
        
        else if(sub_tab == "만억조"){
            try {
                let result = this.$toComma(this.$caculateNumberToMOJ2(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, '')))

                // console.log(result)

                // if (result.includes('.')) {
                //     let parts = result.split('.')
                //     result = parts[0] + '.' + parts[1].substring(0, 2)
                // }

                // console.log(result)
                return result
            } catch (error) {
                return ''
            }
        }

        else if(sub_tab == "KMB"){
            try {
                let result = this.$toComma(this.$caculateNumberToKMB(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, '')))

                // if (result.includes('.')) {
                //     let parts = result.split('.')
                //     result = parts[0] + '.' + parts[1].substring(0, 2)
                // }
                return result
            } catch (error) {
                return ''
            }
        }
    },


    // # 환전 서브 결과값
    result_currency_second_sub(list, sub_tab, currency_origin, currency_second){
        // # 객체계산식을 문자열계산식으로 변환
        let formula = this.$convertFormulaListToString(list)

        // # 계산식에서 빠진 뒷괄호 추가
        formula = this.$addMissingParentheses(formula)

        // # 계산값 표시
        if(sub_tab == "000"){
            try {
                return this.$toComma(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, ''))
            } catch (error) {
                return ''
            }
        }
        
        else if(sub_tab == "만억조"){
            try {
                let result = this.$toComma(this.$caculateNumberToMOJ2(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, '')))

                // if(!result.includes('만')){
                //     return ''
                // }

                if (result.includes('.')) {
                    let parts = result.split('.')
                    result = parts[0] + '.' + parts[1].substring(0, 2)
                }
                return result
            } catch (error) {
                return ''
            }
        }

        else if(sub_tab == "KMB"){
            try {
                let result = this.$toComma(this.$caculateNumberToKMB(this.$math.divide(this.$math.multiply(this.$math.evaluate(formula), this.$math.bignumber(currency_origin)), currency_second).toFixed(2).replace(/\.00$/, '')))

                // if(!result.includes('K')){
                //     return ''
                // }

                if (result.includes('.')) {
                    let parts = result.split('.')
                    result = parts[0] + '.' + parts[1].substring(0, 2)
                }
                return result
            } catch (error) {
                return ''
            }
        }
    },
}

export default methods