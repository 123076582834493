const scales = {
	'만': 10000,
	'억': 100000000,
	'조': 1000000000000,
	'경': 10000000000000000,
	'K': 1000,
	'M': 1000000,
	'B': 1000000000,
	'T': 1000000000000
}

const scalesMOJ = {
    '경': 10000000000000000,
	'조': 1000000000000,
	'억': 100000000,
	'만': 10000
}

const scalesKMB = {
	'T': 1000000000000,
	'B': 1000000000,
	'M': 1000000,
	'K': 1000
}

const methods = {
    removeTrailingZeros(num) {
        const str = num.toString()
        const dotIndex = str.indexOf('.')
    
        if (dotIndex !== -1) {
            return str.replace(/\.?0+$/, '')
        }
        return str
    },

    removeInfiniteDecimal(num) {
        // 소수점 이하 자릿수 확인
        const decimalPlaces = num.toString().split('.')[1]?.length || 0
    
        // 소수점 이하 자릿수가 4보다 크면 무한 소수로 간주하고 소수점 4자리까지 반올림
        if (decimalPlaces > this.$store.state.sosujum) {
            return Number(this.$removeTrailingZeros(num)).toFixed(this.$store.state.sosujum)
        }

        // 그 외의 경우는 그대로 반환
        return num
    },

    // # 객체계산식을 문자열계산식으로 변환
    convertFormulaListToString(list){
        return list.map((item, index) => {
            if (index === list.length - 1 && item.number === null) {
                return ''
            }

            let scaleValue = scales[item.scale] || this.$math.bignumber(1)
            let number = item.number && item.number.toString().includes('%') 
                ? this.$math.divide(this.$math.bignumber(parseFloat(item.number)), this.$math.bignumber(100)) 
                : this.$math.bignumber(item.number)
            if (item.operator === null) {
                return `${this.$math.multiply(this.$math.bignumber(number), scaleValue)}`
            } else {
                return `${item.operator} ${this.$math.multiply(this.$math.bignumber(number), scaleValue)}`
            }
        }).filter(Boolean).join(' ')
    },

    // # 계산식에서 빠진 뒷괄호 추가
    addMissingParentheses(formula) {
        let openCount = (formula.match(/\(/g) || []).length
        let closeCount = (formula.match(/\)/g) || []).length
        
        while (openCount > closeCount) {
            formula += ')'
            closeCount++
        }
    
        return formula
    },

    // # 키 목록에서 괄호 개수 확인
    isParenthesesOpen(list) {
        // 빈 괄호 체크
        if(
            list.at(-1).number == null
            && list.at(-1).operator.includes('(')
        ){
            return false
        }

        let OperatorList = list.map(e => e.operator)
        let openCount = 0
        let closeCount = 0

        // list 돌면서 괄호 수 체크
        OperatorList.forEach(operator => {
            if (operator) {
                openCount += (operator.match(/\(/g) || []).length
                closeCount += (operator.match(/\)/g) || []).length
            }
        })

        // 열린 괄호가 0개인 경우 false
        if (openCount == 0){
            return false
        }
        // 열림 괄호가 1개 이상일 경우
        else if(openCount > 0){
            // 열린 괄호가 더 많을 경우 true
            if(openCount > closeCount){
                return true
            }
            // 열린 괄호가 같은 경우 false
            else{
                return false
            }
        }
    },

    // # 사칙연산 기호 이쁘게 변경
    pretty_operator(operator) {
        // 없을시 null 리턴
        if(!operator) return null
        
        // 각 연산자 보기 이쁜 기호로 변경
        return operator.replace("/", "÷").replace("*", "×").replace("-", "－").replace("+", "＋")
    },

    // # 사칙연산 기호 이쁘게 변경
    pretty_operator2(operator) {
        // 없을시 null 리턴
        if(!operator) return ""
        
        // 각 연산자 보기 이쁜 기호로 변경
        return operator.replace("/", "÷").replace("*", "×").replace("-", "－").replace("+", "＋")
    },

    // # 원래 기호로 변경
    unpretty_operator(operator) {
        // 없을시 null 리턴
        if(!operator) return null
        
        // 각 이쁜 기호를 원래 기호로 변경
        return operator.replace("÷", "/").replace("×", "*").replace("－", "-").replace("＋", "+")
    },

    // # 숫자 X 단위 => 계산된 숫자
    caculateTo000(number, scale) {
        // 단위 없을 시
		if (scale === null) {
            // BigNumber만 처리
			return this.$math.bignumber(number)
		}

        // 단위 있을 시
        else{
            // 단위랑 곱셈 처리
            return this.$math.multiply(this.$math.bignumber(number), scales[scale])
        }
	},

    // # 숫자 -> 계산된 숫자 + 만억조
    caculateNumberToMOJ(number) {
        // scalesMOJ 돌면서 몫 + 단위 => 나머지는 다시 반복
        let result = ''
        for (let unit in scalesMOJ) {
            if (this.$math.largerEq(number, scalesMOJ[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scalesMOJ[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scalesMOJ[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number
        }

        return result
    },

    // # 숫자 -> 계산된 숫자 + 만억조
    caculateNumberToMOJ2(number) {
        // NaN 또는 Infinity 체크
        if (isNaN(number) || !isFinite(number)) {
            return ''
        }

        let isNegative = false
        if (this.$math.smaller(number, 0)) {
            isNegative = true
            number = this.$math.abs(number)
        }
        
        // scalesMOJ 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scalesMOJ) {
            if (this.$math.largerEq(number, scalesMOJ[unit])) {
                const quotient = this.$math.divide(number, scalesMOJ[unit])
                if (this.$math.largerEq(quotient, 1) && this.$math.equal(quotient, this.$math.round(quotient, 2))) {
                    let result = quotient.toLocaleString('en', {maximumFractionDigits: 2}) + unit
                    return isNegative ? '-' + result : result
                }
                break
            }
        }

        let result = ''
        for (let unit in scalesMOJ) {
            if (this.$math.largerEq(number, scalesMOJ[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scalesMOJ[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scalesMOJ[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number.toLocaleString('en')
        }

        result = result.trim()
        return isNegative ? '-' + result : result
    },

    // # 숫자 -> 계산된 숫자 + KMB
    caculateNumberToKMB(number) {
        // scalesKMB 돌면서 몫 + 단위 => 나머지는 다시 반복
        let result = ''
        for (let unit in scalesKMB) {
            if (this.$math.largerEq(number, scalesKMB[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scalesKMB[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scalesKMB[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number
        }

        return result.trim()
    },

    // # 숫자 -> 계산된 숫자 + KMB
    caculateNumberToKMB2(number) {
        // NaN 또는 Infinity 체크
        if (isNaN(number) || !isFinite(number)) {
            return ''
        }

        let isNegative = false
        if (this.$math.smaller(number, 0)) {
            isNegative = true
            number = this.$math.abs(number)
        }

        // scalesKMB 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scalesKMB) {
            if (this.$math.largerEq(number, scalesKMB[unit])) {
                const quotient = this.$math.divide(number, scalesKMB[unit])
                if (this.$math.largerEq(quotient, 1) && this.$math.equal(quotient, this.$math.round(quotient, 2))) {
                    let result = quotient.toLocaleString('en', {maximumFractionDigits: 2}) + unit
                    return isNegative ? '-' + result : result
                }
                break
            }
        }

        let result = ''
        for (let unit in scalesKMB) {
            if (this.$math.largerEq(number, scalesKMB[unit])) {
                const quotient = this.$math.floor(this.$math.divide(number, scalesKMB[unit]))
                result += quotient + unit + ' '
                number = this.$math.mod(number, scalesKMB[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(number, 0)) {
            result += number.toLocaleString('en')
        }

        result = result.trim()
        return isNegative ? '-' + result : result
    },

    // # 숫자 + 단위(KMB) -> 계산된 숫자 + 만억조
    caculateKMBtoMOJ(number, scale){
        // 값 세팅
        let value = this.$math.multiply(this.$math.bignumber(number), scalesKMB[scale])
        let result = ''

        /// scalesMOJ 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scalesMOJ) {
            if (this.$math.largerEq(value, scalesMOJ[unit])) {
                const quotient = this.$math.floor(this.$math.divide(value, scalesMOJ[unit]))
                result += quotient + unit + ' '
                value = this.$math.mod(value, scalesMOJ[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(value, 0)) {
            result += value
        }

        // 결과값 + trim() 없으면 곱셈값 리턴
        return result.trim() || value.toString()
    },

    // # 숫자 + 단위(만억조) -> 계산된 숫자 + KMB
    caculateMOJtoKMB(number, scale){
        // 값 세팅
        let value = this.$math.multiply(this.$math.bignumber(number), scalesMOJ[scale])
        let result = ''

        // scalesKMB 돌면서 몫 + 단위 => 나머지는 다시 반복
        for (let unit in scalesKMB) {
            if (this.$math.largerEq(value, scalesKMB[unit])) {
                const quotient = this.$math.floor(this.$math.divide(value, scalesKMB[unit]))
                result += quotient + unit + ' '
                value = this.$math.mod(value, scalesKMB[unit])
            }
        }

        // 위 반복문 돌린 후 number의 남은 값 마지막에 추가
        if (this.$math.larger(value, 0)) {
            result += value
        }

        // 결과값 + trim() 없으면 곱셈값 리턴
        return result.trim() || value.toString()
    }
}

export default methods