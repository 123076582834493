const methods = {
    // # 마지막 키가 숫자인지
    isLastKeyNumberKey() {
        const keyList = this.$store.state.keyList
        return keyList.length && this.$keyType(keyList[keyList.length - 1]) === "숫자키"
    },

    // # 마지막 숫자키 조합
    lastNumberKeySet() {
        const keyList = this.$store.state.keyList
        let result = []

        for (let i = keyList.length - 1; i >= 0; i--) {
            if (this.$keyType(keyList[i]) === '숫자키') {
                result.unshift(keyList[i])
            } else {
                break
            }
        }

        return result.join('')
    },

    // # 마지막 숫자키 조합
    lastNumberKeySet2() {
        const keyList = this.$store.state.keyList
        let result = []

        for (let i = keyList.length - 2; i >= 0; i--) {
            if (this.$keyType(keyList[i]) === '숫자키') {
                result.unshift(keyList[i])
            } else {
                break
            }
        }

        return result.join('')
    },

    // # key 저장
    keyList_Handler(key, list){
        if(this.$keyType(key) == '기능키'){
            this.$store.commit('reset_keyList')
        }
        else if(this.$keyType(key) == '숫자키'){
            this.$store.commit('push_keyList', key)
        }
        else if(this.$keyType(key) == '연산자키'){
            this.$store.commit('push_keyList', key)
        }
        else if(this.$keyType(key) == '단위키'){
            this.$store.commit('push_keyList', key)
        }

        this.$checkDisableKey(list)
    },

    // ! 비활성 키 설정
    checkDisableKey(list){
        // # 숫자 수정모드
        if(this.$store.state.edit_mode == true){
            let editItem = list.find(e => e.edit)

            // 수정모드 : 괄호, 연산자, =, Tab 비활성화
            this.$store.commit('set_disable_key', ['(', ')', '/', '*', '-', '+', '='])


            // 수정입력값 : . 여부에 따라 추가 or 삭제
            if(editItem && editItem.edit_number.includes('.')){
                this.$store.commit('add_disable_key', ['.'])
            }
            else{
                this.$store.commit('remove_disable_key', ['.'])
            }

            // 수정입력값 : % 여부에 따라 추가 or 삭제
            if(editItem && editItem.edit_number.includes('%')){
                this.$store.commit('add_disable_key', ['%'])
            }
            else{
                this.$store.commit('remove_disable_key', ['%'])
            }

            // 서브탭 000 : 만억조, KMB 키 비활성화
            if(this.$store.state.sub_tab == "000"){
                this.$store.commit('add_disable_key', ['만', '억', '조', 'K', "M', 'B'"])
            }
        }

        // # 연산자 수정모드
        else if(this.$store.state.edit_mode2 == true){
            this.$store.commit('set_disable_key', ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00', '.', '%', '만', '억', '조', 'K', 'M', 'B'])

            if(this.$isParenthesesOpen(list)){
                this.$store.commit('remove_disable_key', [')'])
            }else{
                this.$store.commit('add_disable_key', [')'])
            }
        }

        // # 일반모드
        else{
            this.$store.commit('set_disable_key', [])

            // 아무것도 없을시
            if(list.length == 0){
                this.$store.commit('set_disable_key', [')', '/', '*', '+', '=', '00', '%', '←'])
                return
            }

            // 0만 계속 입력 불가
            if(list.at(-1) && list.at(-1).number == '0'){
                this.$store.commit('add_disable_key', ['0', '00'])
            }

            // 00, % 먼저 입력 불가
            if(list.at(-1) && list.at(-1).number == null){
                this.$store.commit('add_disable_key', ['00', '%'])
            }

            // 숫자값 : . 여부에 따라 추가 or 삭제
            if(list.at(-1) && list.at(-1).number && list.at(-1).number.toString().includes('.')){
                this.$store.commit('add_disable_key', ['.'])
            }

            // 수정입력값 : % 여부에 따라 추가 or 삭제
            if(list.at(-1) && list.at(-1).number && list.at(-1).number.toString().includes('%')){
                this.$store.commit('add_disable_key', ['%', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00', '.', '만', '억', '조', 'K', 'M', 'B'])
            }

            // 시작 괄호 뒤에 사칙연산 기호 입력 불가
            if(list.at(-1) && list.at(-1).operator && list.at(-1).operator.slice(-1) == "(" && list.at(-1).number == null){
                this.$store.commit('add_disable_key', [')', '/', '*', '-', '+', '='])
            }

            // 뒷괄호 가능 여부 체크
            if(
                !this.$isParenthesesOpen(list) // 열려있는 괄호 개수가 같은 경우 (빈 괄호 체크 포함)
            ){
                this.$store.commit('add_disable_key', [')'])
            }
        }
    },

    // # 키 타입 분류
    keyType(key){
        // 기능키
        if(
            key == 'AC'
            || key == '←'
            || key == 'Tab'
            || key == '='
        ){
            return "기능키"
        }

        // 연산자키
        else if(
            key == '('
            || key == ')'
            || key == '/'
            || key == '*'
            || key == '-'
            || key == '+'
        ){
            return "연산자키"
        }

        // 숫자키
        else if(
            key == '1'
            || key == '2'
            || key == '3'
            || key == '4'
            || key == '5'
            || key == '6'
            || key == '7'
            || key == '8'
            || key == '9'
            || key == '0'
            || key == '00'
            || key == '.'
            || key == '%'
        ){
            return "숫자키"
        }

        // 단위키
        else if(
            key == '만'
            || key == '억'
            || key == '조'
            || key == 'K'
            || key == 'M'
            || key == 'B'
        ){
            return "단위키"
        }

        // 없음
        else{
            return "없음"
        }
    }
}

export default methods